import { render, staticRenderFns } from "./UsersByRole.vue?vue&type=template&id=c553b924&scoped=true&"
import script from "./UsersByRole.vue?vue&type=script&lang=js&"
export * from "./UsersByRole.vue?vue&type=script&lang=js&"
import style0 from "./UsersByRole.vue?vue&type=style&index=0&id=c553b924&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c553b924",
  null
  
)

component.options.__file = "UsersByRole.vue"
export default component.exports