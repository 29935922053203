import { render, staticRenderFns } from "./AddSchools.vue?vue&type=template&id=3582b3ee&scoped=true&"
import script from "./AddSchools.vue?vue&type=script&lang=js&"
export * from "./AddSchools.vue?vue&type=script&lang=js&"
import style0 from "./AddSchools.vue?vue&type=style&index=0&id=3582b3ee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3582b3ee",
  null
  
)

component.options.__file = "AddSchools.vue"
export default component.exports