<template>
    <div class="management-dashboard-section-container">
        <div class="section-header row">
            <label for="initialDate">{{$t("from")}}</label>
            <datepicker v-model="initialDate" 
                        :language="datePickerLanguage" 
                        :format="dateSlashFormatted"
                        :disabled-dates="{ from: finalDate }"></datepicker>

            <label for="finalDate">{{$t("to")}}</label>
            <datepicker v-model="finalDate" 
                        :language="datePickerLanguage" 
                        :format="dateSlashFormatted"
                        :disabled-dates="{ to: initialDate }"></datepicker>

            <label for="roles">
                {{`${withCapitalLetter($t("roles"))}:`}}</label>
            <select name="roles" v-model="selectedRole">
                <option disabled v-bind:v-model="null">
                    {{$t("defaultSelectOption")}}</option>
                <option v-for="role in roles"
                        :key="role.roleId"
                        :value="role.roleId">{{role.name}}</option>
            </select>
            
            <button v-on:click="searchTelemetries">
                <span>{{withCapitalLetter($t("search"))}}</span>
            </button>
        </div>
        <div class="section-body">
            <table>
                <thead>
                    <tr>
                        <th>{{withCapitalLetter($t("sectionName"))}}</th>
                        <th>{{withCapitalLetter($t("numberOfVisits"))}}</th>
                        <th>{{`${withCapitalLetter($t("averageDuration"))} (HH:mm:ss)`}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(telemetry, index) in partialCollection" :key="index">
                        <td>{{telemetry.pageView.name}}</td>
                        <td class="centered-column">{{telemetry.performanceCounter.counter}}</td>
                        <td class="centered-column">{{fromMiliToFormattedDate(telemetry.averageDuration)}}</td>
                    </tr>
                    <tr v-if="partialCollection.length === 0">
                        <td class="no-content" colspan="3">{{$t("noContent")}}</td>
                    </tr>
                </tbody>
            </table>
            
            <paginator  :collection="collection" 
                        v-on:onPartialCollectionUpdate="eventHandler"/>
        </div>
    </div>
</template>

<script>

import Paginator from "@/common/Paginator.vue";
import StringUtils from "@/utils/StringUtils.js";
import DateTimeUtils from "@/utils/DateTimeUtils.js";
import Datepicker from 'vuejs-datepicker';
import {en, es} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import AuthenticationService from "@/services/AuthenticationService.js";
import { Role } from "@/utils/Roles.js" ;

export default {
    name: "TopVisitedSectionsPerRole",
    components: {
        Paginator,
        StringUtils,
        DateTimeUtils,
        Datepicker
    },
    data: function(){
        return {
            initialDate: moment().subtract(1, 'd').toDate(),
            finalDate: moment().toDate(),
            selectedRole: null,
            partialCollection: []
        }
    },
    computed: {
        roles(){
            let _roleName = this.currentUser.roleName.replace(/\s/g, "");

            return Role[_roleName].toAddAllowedRoles();
        },
        collection(){
            return this.$store.state.managementObj.telemetries;
        },
        datePickerLanguage(){
            return this.$i18n.locale === "en" ? en : es;
        },
        currentUser(){
            return AuthenticationService.getProfile();
        }
    },
    methods: {
        eventHandler(event){
            this.partialCollection = event;
        },
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        },
        dateSlashFormatted(date){
            if(!date || date === "")
                return date;

            const enFormat = "MM/DD/YYYY";
            const esFormat = "DD/MM/YYYY";

            let format = this.$i18n.locale === "en" ? enFormat : esFormat;
            return moment(date).format(format);
        },
        dateDashFormatted(date){
            let _date = null;

            return !date || date === "" || !(_date = moment(date)).isValid() ?
                    date : _date.format("YYYY-MM-DD");
        },
        searchTelemetries(){
            let _customDimensions = {
                userRoleId: this.selectedRole
            };

            if(this.currentUser.roleName === Role.DistrictManager.name)
                _customDimensions.districtId = this.currentUser.districtId;
            else if(this.currentUser.roleName === Role.CampusManager.name)
                _customDimensions.schoolId = this.currentUser.schoolId;

            this.$store.dispatch("getTopVisitedSections", { 
                customDimensions: _customDimensions,
                timespan: this.getQueryTimeSpan()
            })
            .then(null, error => {
                this.$store.commit('addSnackBarNotification', {
                    message: this.$t(error), 
                    dismissable: true
                });
            });
        },
        fromMiliToFormattedDate(miliseconds){
            return DateTimeUtils.fromMiliToFormattedTime(miliseconds);
        },
        getQueryTimeSpan(){
            let _initialDate = this.dateDashFormatted(this.initialDate);
            let _finalDate = this.dateDashFormatted(moment(this.finalDate).add(1, 'd'));

            return `${_initialDate}/${_finalDate}`;
        }
    },
    created: function(){
        this.selectedRole = this.roles[0].roleId;
        this.searchTelemetries();

        this.$store.commit("setManagementDashboardNavBarPath", {
            parent: this.$t("management"),
            child: this.$t("analytics")
        });
    }
}

</script>

<style lang="less" scoped>

.management-dashboard-section-container{
    width: 968px;
    margin: 10px auto;

    .section-header{
        height: 56px;
        color: white;
        background-color: #0A1554;
        line-height: 56px;

        & /deep/ .vdp-datepicker{
            color: black;
        }

        & /deep/ .vdp-datepicker input,
        select{
            min-height: 24px;
            max-height: 24px;
            margin-right: 40px;
        }

        label{
            margin-left: auto;
            margin-right: 10px;
        }

        select{
            width: 150px;
            margin-top: 16px;

            option{
                line-height: 16px;
                font-size: 16px;
            }
        }

        button{
            background-color: #FFFFFF;

            height: 40px;
            width: 108px;

            box-sizing: border-box;
            border: 2px solid #4356FD;
            border-radius: 20px;

            margin-top: 8px;
            margin-right: 10px;

            &:hover{
                cursor: pointer;
            }

            &:focus{
                outline: none;
            }

            span{
                height: 16px;
                width: 175px;
                color: #3A4DFD;
                font-family: Montserrat;
                font-size: 13.96px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;
                text-align: center;
            }
        }
    }

    .section-body{
        max-width: 968px;
        height: calc(100vh - 210px);
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }

        table{
            width: 100%;
            border-collapse: collapse;

            tr{
                height: 56px;
            }

            thead{
                th{
                    text-align: center;
                    width: 30%;

                    &:first-child{
                        text-align: left;
                        width: 40%;
                    }
                }
            }

            tbody{
                border-top: 1px solid grey;
                border-bottom: 1px solid grey;

                .no-content, .centered-column{
                    text-align: center;
                }
            }
        }

        & /deep/ .paginator-container{
            margin-top: 5px;
        }
    }
}

</style>

