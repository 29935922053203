<template>
    <div class="add-element-container column">
        <div class="row">
            <p>{{this.$t("addDistrictsText")}}</p>
            <i class="circle close-tag" v-on:click="hideModal"> 
                <div class="cross"></div>
            </i>
        </div>
        <div class="add-element-fields column">
            <label for="name" 
                :class="[{visibility: districtName == '', errorMessage: !isValidDistrictName}]">
                {{`${isValidDistrictName ? $t("addDistrictNameLabelText") 
                                        : !isDistrictNameAvailable ? 
                                                $t("invalidName") 
                                                : $t("mustFillNameField")}`}}</label>
            <input type="text" name="name" 
                    v-on:blur="checkDistrictNameAvailabality" 
                    v-on:focus="focusEventHandler"
                    v-model="districtName" 
                    :placeholder='$t("addDistrictNameLabelText")'/>

            <label for="states">{{withCapitalLetter($t("states"))}}</label>
            <select name="states" v-model="selectedState">
                <option disabled :value="null">{{$t("defaultSelectOption")}}</option>
                <option v-for="(state, index) in states" 
                    v-bind:key="index" v-bind:value="state.id">
                    {{state.name}}
                </option>
            </select>
        </div>

        <div class="add-element-buttons row">
            <button @click="cleanFormFields()">
                <span class='clean-button-text'>
                    {{$t("cleanButtonText")}}</span>
            </button>
            <button :class="[{'disabled': !validForm}]"
                    @click="addDistricts()">
                <span class='add-element-submit-button-text'>
                    {{$t("addButtonText")}}</span>
            </button>
        </div>
    </div>
</template>

<script>

import StringUtils from "@/utils/StringUtils.js";

export default{
    name: "AddDistricts",
    components: {
        StringUtils
    },
    data: function(){
        return {
            districtName: "",
            selectedState: null,
            isDistrictNameAvailable: true,
        };
    },
    computed: {
        validForm(){
            return  this.isValidDistrictName && this.isValidSelectedState;
        },
        isValidDistrictName(){
            return this.districtName.trim() != "" && this.isDistrictNameAvailable;
        },
        isValidSelectedState(){
            return this.selectedState != null;
        },
        states(){
            return this.$store.state.managementObj.states;
        },
        currentState(){
            return this.$store.state.managementObj.currentState;
        }
    },
    methods: {
        addDistricts(){
            if(!this.validForm){
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t("mustFillFields"), 
                        dismissable: false
                    });

                return;
            }

            this.$store.state.dealerPromise.promise
                .then(() => {
                    this.$store.dispatch("addNewDistrict", { 
                        name: this.districtName,
                        stateId: this.selectedState,
                        currentState: this.currentState
                    }).then(() => {
                        this.hideModal();
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t("successfulAddDistrictsMessage"), 
                                dismissable: false
                            });
                    }, error => {
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t(error), 
                                dismissable: false
                            });
                    });
                }, () => {
                    this.$store.commit('addSnackBarNotification', {
							message: this.$t("invalidDistrictName"), 
							dismissable: false
						});
                });
        },
        cleanFormFields(){
            this.districtName = "";
            this.selectedState = null;
        },
        checkDistrictNameAvailabality(){
            if(!this.districtName)
                return;

            this.$store.dispatch("checkDistrictNameAvailabality", this.districtName)
                        .then(() => { this.isDistrictNameAvailable = false; })
                        .catch(() => { this.isDistrictNameAvailable = true; });
        },
        focusEventHandler(){
            this.$store.dispatch("setDealerPromise");
            this.isDistrictNameAvailable = true;
        },
        hideModal(){
            this.$emit("close");
        },
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        }
    },
    beforeDestroy(){
        this.$store.commit("setDealerPromise", null);
    }
}
</script>
<style lang="less" scoped>
.add-element-container{
    margin: 0px auto;
    width: 329px;
    padding: 20px 0px;

    p{
        margin: 0;

        width: 227px;
        color: rgba(0,0,0,0.77);
        font-family: Montserrat;
        font-size: 24px;
        line-height: 32px;
    }

    .close-tag{
        margin-top: 4px;
        margin-left: 78px;
        background-color: #0A1554;
    }

    .add-element-fields{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 26px;

        label{
            height: 24px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 12px;

            margin-bottom: 6px;

            &.visibility{
                visibility: hidden;
            }
        }

        .errorMessage{
            color: red;
        }

        input, select{
            width: 100%;
            min-height: 24px;
            max-height: 24px;

            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;

            &:focus{
                outline: none;
            }
        }

        input{
            margin-bottom: 23px;
            
            border: none;
            border-bottom: 1px solid #9E9E9E;
            padding-bottom: 3px;
            
            &:hover{
                cursor: text;
            }
        }
    }

    .add-element-buttons{
        width: 100%;
        margin-bottom: 0;

        button{
            border-radius: 20px;
            text-align: center;

            &:hover{
                cursor: pointer;
            }

            &:focus{
                outline: none;
            }
        }

        button:first-child{
            height: 40px;
            width: 108px;

            box-sizing: border-box;
            border: 2px solid #4356FD;
            background-color: #FFFFFF;

            .clean-button-text{
                height: 16px;
                width: 175px;
                color: #3A4DFD;
                font-family: Montserrat;
                font-size: 13.96px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;
                text-align: center;
            }
        }

        button:nth-child(2){
            height: 42px;
            width: 199px;

            margin-left: 23px;
            box-sizing: border-box;

            background-color: #0A1554;
            border: none;

            .add-element-submit-button-text{
                height: 16px;
                width: 64px;
                color: white;
                font-family: Montserrat;
                font-size: 13.96px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;
                text-align: center;
            }
        }

        button.disabled{
            background-color: rgba(158, 158, 158, 0.801);
        }
    }
}
</style>
